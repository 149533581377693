<template>
  <div class="d-flex flex-row flex-wrap mb-4">
    <b-badge
      v-for="link in links"
      :key="link.key"
      :variant="link.color"
      class="mr-1 mt-1 cursor-pointer"
      @click="scrollToElement(link.key)"
    >
      {{ $t(link.short) }}
    </b-badge>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import { kucSections, kycStatusColors } from '@/views/apps/kyc/config/kycTypes'

export default {
  name: 'KycLinks',
  components: {
    BBadge,
  },
  props: {
    mainData: {
      type: Object,
      default: () => ({}),
    },
    isTotalGrey: {
      type: Boolean,
      default: false,
    },
    linksCustom: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      kucSections,
    }
  },
  computed: {
    links() {
      if (this.linksCustom.length > 0) {
        return this.linksCustom
      }
      const getColor = key => {
        if (this.isTotalGrey) {
          return 'secondary'
        }
        const item = this.mainData.checks[key]
        if (key === 'cdd' && !item) {
          return 'success'
        }

        if (!item || !Array.isArray(item) || item.length === 0) {
          return 'secondary'
        }
        return kycStatusColors[item[0].status]
      }

      return this.kucSections.map(section => ({
        ...section,
        color: getColor(section.key),
      }))
    },
  },
  methods: {
    scrollToElement(elementId) {
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>
